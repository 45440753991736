import { CSSProperties, ForwardedRef, forwardRef, PropsWithChildren, ReactNode, RefAttributes } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperProps, SwiperRef, SwiperSlide, SwiperSlideProps } from 'swiper/react';

import { Id } from '@/shared/types';
import { PropsWithClassName } from '@/shared/types/util';

import Picture from '../Picture';

import 'swiper/css';
import style from './Carousel.module.css';

export type CarouselSlide = SwiperSlideProps & PropsWithChildren & PropsWithClassName;

export type BaseCarouselProps = RefAttributes<SwiperRef> & PropsWithChildren<SwiperProps>;

export interface CarouselProps extends BaseCarouselProps {
  activeId?: Id;
  list?: Record<string, any>[];
  onMouseOverSlide?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  renderItem?: (item: Record<string, any>, index: number) => ReactNode | null;
  addActiveStyles?: (id: Id) => CSSProperties;
}

export function CarouselSlide(props: CarouselSlide) {
  const { children, ...rest } = props;

  return <SwiperSlide {...rest}>{children}</SwiperSlide>;
}

CarouselSlide.displayName = 'SwiperSlide';

export function CarouselInner(props: CarouselProps, ref: ForwardedRef<SwiperRef>) {
  const { activeId, addActiveStyles, children, list, pagination, renderItem, className, onMouseOverSlide, ...rest } =
    props;

  if (!list?.length) {
    return null;
  }

  return (
    <Swiper
      ref={ref}
      {...rest}
      pagination={pagination}
      className={classNames(style.root, className, { [style.pagination]: pagination })}
    >
      {list?.map((item, index) => {
        const safeId = item?.id ?? 0;
        const activeStyle = activeId === safeId ? addActiveStyles?.(activeId!) : {};

        return (
          <CarouselSlide
            onMouseOver={onMouseOverSlide}
            key={`carousel-slide-${index}`}
            style={activeStyle}
            className={style.item}
          >
            {renderItem?.(item, index) ?? <Picture {...(item ?? {})} className='carousel-slide__picture' />}
          </CarouselSlide>
        );
      })}
      {children}
    </Swiper>
  );
}

const Carousel = forwardRef(CarouselInner);

export default Carousel;
