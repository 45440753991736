'use client';

import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { Navigation, Pagination } from 'swiper/modules';

import { NEXT_PUBLIC_CDN_ROOT } from '../../../../cfg';
import Carousel from '../../../../src/shared/ui/Carousel/Carousel';

import styles from './Slider.module.css';


const Slider = ({ slider }) => {
  const renderSlide = (slide, index) => {
    if (!slide) {
      return null;
    }

    const slideId = slide?.id;
    // const slideButtonText = slide?.buttonText || null;
    const slideLink = slide?.link || null;
    const slideName = slide?.name || null;
    const slidePicture = slide?.picture || null;
    const slideSliderText = slide?.sliderText || null;
    const slideLabelText = slide?.labelText || null;

    const content = (
      <>
        {slidePicture && (
          <Image
            priority={index < 1}
            loading={index > 0 ? 'lazy' : 'eager'}
            fill
            quality={50}
            itemProp='image'
            alt={slideLabelText ?? slideSliderText ?? ''}
            src={`${NEXT_PUBLIC_CDN_ROOT}${slidePicture?.src}`}
            sizes='(min-width: 1921px) 1040px, (min-width: 1538px) 1040px, (min-width: 1441px) 960px, (min-width: 1281px) 800px, (min-width: 769px) 640px, 320px'
            style={{
              objectFit: 'contain'
            }}
          />
        )}
      </>
    );

    if (slideLink) {
      return (
        (
          <Link
            href={slideLink || ''}
            key={`slide-${slideId}`}
            prefetch={false}
            className={classnames(styles.mainFeaturesSliderItem, 'banner-item')}
            data-banner-id={slideId}
            data-banner-name={slideName}
            data-banner-href={slideLink}
            data-banner-index={index + 1}
            data-event-type='click'
            data-event='promoClick'
            data-event-category='Главная страница'
            data-event-action='Верхний слайдер'
            data-event-label={slideSliderText}
            data-event-ecommerce={JSON.stringify({
              promoClick: {
                promotions: [{
                  id: slideId, // если есть, можно не передавать
                  name: slideName, // н-р Ковры в загородный дом
                  position: 'Главная. Верхний слайдер'
                }]
              }
            })}
          >
            {content}
          </Link>
        )
      );
    }

    return (
      <div
        key={`slide-${slideId}`}
        className={classnames(styles.mainFeaturesSliderItem, 'banner-item')}
        data-banner-id={slideId}
        data-banner-name={slideName}
        data-banner-href={slideLink}
        data-event-type='click'
        data-event='promoClick'
        data-event-category='Главная страница'
        data-event-action='Верхний слайдер'
        data-event-label={slideSliderText}
        data-event-ecommerce={JSON.stringify({
          promoClick: {
            promotions: [{
              id: slideId, // если есть, можно не передавать
              name: slideName, // н-р Ковры в загородный дом
              position: 'Главная. Верхний слайдер'
            }]
          }
        })}
      >
        {content}
      </div>
    );
  };


  return (
    <Carousel
      className={classnames(styles.mainFeaturesSlider, 'banner-items')}
      loop
      slidesPerView={1}
      modules={[Pagination, Navigation]}
      navigation
      pagination={{ clickable: true }}
      list={slider}
      renderItem={renderSlide}
    />
  );
}

export default Slider;
