'use client';

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Link from 'next/link';

import styles from './MobileFeaturesBanner.module.css';

const MobileFeaturesBanner = ({ className, isFreeShippingDisabled }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (document.readyState !== 'complete') {
      const handler = () => {
        setTimeout(() => {
          setIsLoaded(true);
        }, 2000);
      };

      window.addEventListener('load', handler);

      return () => {
        window.removeEventListener('load', handler);
      };
    }

    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoaded) {
        setCurrentSlide(c => (c + 1) % 3);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [isLoaded]);

  return (
    <div className={classnames(styles.mainMobileFeaturesContent, className)}>
      <div className={classnames(styles.mainMobileFeaturesItems)}>
        <Link
          href='/dostavka-i-oplata/#shipping'
          className={classnames(styles.mainMobileFeaturesItem, { [styles.active]: currentSlide === 0 })}
        >
          <img src='/static/imgs/main/mobile-delivery-icon.svg' width='20' height='17' alt='Бесплатная доставка' loading='lazy' />
          {!isFreeShippingDisabled ? 'Бесплатная доставка' : 'Доставка'}
        </Link>
        <Link
          href='/demonstration/'
          prefetch={false}
          className={classnames(styles.mainMobileFeaturesItem, { [styles.active]: currentSlide === 1 })}
        >
          <img src='/static/imgs/main/mobile-fit-icon.svg' width='23' height='17' alt='Бесплатная демонстрация ковров у вас дома' loading='lazy' />
          {!isFreeShippingDisabled ? 'Бесплатная демонстрация' : 'Демонстрация'}{' '}ковров у вас дома
        </Link>
        <Link href='/vozvrat/' className={classnames(styles.mainMobileFeaturesItem, { [styles.active]: currentSlide === 2 })}>
          <img src='/static/imgs/main/mobile-return-icon.svg' width='27' height='17' alt='Бесплатный возврат в течение 100 дней' loading='lazy' />
          {!isFreeShippingDisabled ? 'Бесплатный возврат' : 'Возврат'}{' '}в течение 100 дней
        </Link>
      </div>
    </div>
  );
};

export default MobileFeaturesBanner;
