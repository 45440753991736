/* eslint-disable react/no-array-index-key */
import React, { ElementType, forwardRef, HTMLAttributes, memo } from 'react';
import { NEXT_PUBLIC_API_ROOT, NEXT_PUBLIC_CDN_ROOT } from 'cfg';

import { PictureSource, PictureSourceSet } from '@/shared/types';
import { PropsWithClassName } from '@/shared/types/util';

interface PictureSourcesProps {
  isAbsolutePath?: boolean;
  list: PictureSource[];
  getSourceSrc?: null | ((source: Partial<PictureSourceSet> | string) => string);
}

export interface PictureProps extends Omit<HTMLAttributes<HTMLImageElement>, 'placeholder'>, PropsWithClassName {
  isAbsolutePath?: boolean;
  source?: PictureSource[];
  fallback?: ElementType;
  priority?: boolean;
  getSourceSrc?: null | ((source: Partial<PictureSourceSet> | string) => string);
}

export const getDefaultCdnSrc = (source: unknown) => {
  if (!source) {
    return '';
  }

  if (typeof source === 'string') {
    return `${NEXT_PUBLIC_CDN_ROOT}${source}`;
  }

  const { url, density } = source as Partial<PictureSourceSet>;

  return `${NEXT_PUBLIC_CDN_ROOT}${url}${density ? ` ${density}` : ''}`;
};

export const getDefaultApiSrc = (source: unknown) => {
  if (!source) {
    return '';
  }

  if (typeof source === 'string') {
    return `${NEXT_PUBLIC_API_ROOT}${source}`;
  }

  const { url, density } = source as Partial<PictureSourceSet>;

  return `${NEXT_PUBLIC_API_ROOT}${url}${density ? ` ${density}` : ''}`;
};

const PictureSources = (props: Partial<PictureSourcesProps>) => {
  const { list, getSourceSrc, isAbsolutePath } = props;

  if (!list?.length) {
    return null;
  }

  return (
    <>
      {list.map((item, i) => {
        const { media, srcset } = item;
        const mapSrc = (srcItem) => (isAbsolutePath ? srcItem.url : getSourceSrc?.(srcItem));

        const itemSrcSet =
          Array.isArray(srcset) && srcset?.length ? srcset.map(mapSrc).join(', ') : mapSrc(srcset) ?? '';

        if (!itemSrcSet) {
          return null;
        }

        return <source key={`source-item-${i}`} srcSet={itemSrcSet!} media={media} type='image/webp' />;
      })}
    </>
  );
};

const Picture = forwardRef<HTMLImageElement, PictureProps>((props, ref) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id: _id,
    alt,
    src: staticSrc,
    className,
    fallback,
    source,
    getSourceSrc = getDefaultCdnSrc,
    isAbsolutePath,
    onMouseMove,
    onMouseEnter,
    onMouseLeave,
    ...restProps
  } = props;
  const src = isAbsolutePath ? staticSrc : getDefaultCdnSrc?.(staticSrc) ?? staticSrc;

  const FallbackCmp = fallback || 'img';

  if (!src) {
    return null;
  }

  return (
    <picture className={className} onMouseMove={onMouseMove} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <PictureSources isAbsolutePath={isAbsolutePath} list={source} getSourceSrc={getSourceSrc} />

      <FallbackCmp ref={ref} alt={alt} src={src} className='picture__img' {...restProps} />
    </picture>
  );
});

Picture.displayName = 'Picture';

export default memo(Picture);
